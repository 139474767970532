//! – ON DOM LOAD
(function($) {

	$(function() {
		if ( $('.lesson-slides').length > 0 ) {
		
			// Init slick v1.3.15
			var $slider = $('.lesson-slides').slick({
				arrows: false,
				dots: true,
				speed: 1000,
				infinite: false,
				appendDots: '.lesson-slides-dots-wrap',
				onAfterChange: function(){
					update_slick_nav();
				}
			});
			var $sliderWrap = $slider.parent();
	
			// Add tooltips to slick dots
			var $slides = $slider.find('.slick-slide');
			var $dots = $sliderWrap.find('.slick-dots > li');
			
			for (var i = 0; i < $dots.length; i++) {
				var $dotButton = $( $dots[i] ).find('button');
				var slideTitle = $( $slides[i] ).find('h2').text();
				
				$dotButton.attr({
					'data-tooltip': '',
					'aria-haspopup': true,
					'title': slideTitle
				}).addClass('has-tip');
			}
			
			$(document).foundation();
	
			// Hook up external nav buttons
			function update_slick_nav() {
				var currentSlide = $slider.slickCurrentSlide();
				
				if (currentSlide == 0) {
					$slickPrev.prop('disabled', true);
				} else {
					$slickPrev.prop('disabled', false);
				}
				if (currentSlide == $slides.length - 1) {
					$slickNext.prop('disabled', true);
				} else {
					$slickNext.prop('disabled', false);
				}
			} // update_slick_nav()
			
			var $slickPrev = $sliderWrap.find('.slick-prev').on('click', function(){
				$slider.slickPrev();
			});
			var $slickNext = $sliderWrap.find('.slick-next').on('click', function(){
				$slider.slickNext();
			});
	
			update_slick_nav();

		} // if lesson slides
		
		relatedSizingCheck($(window).width());
		

		if( $('body.single-lesson').length && $('.lesson-relations').length ) {
			$('.lesson-relations').appendTo( $('.inner-content') );
		}	

	}); // jQuery document ready

	$(window).on('resize', function() {
		relatedSizingCheck($(window).width());	
	});

})(jQuery);

//! – AFTER ALL ASSETS LOAD

(function($){
	$(window).load(function(){

		// Audio player
		$('audio').on('play', function(){
			console.log('Play!');
			$(this).closest('.mejs-audio').addClass('is-expanded');
		});
		
	});
})( jQuery );

function relatedSizingCheck(width) {
	if( $('body.single-lesson').length > 0 && $('.lesson-relations').length > 0 ) {
		let relatedArticles = $('.lesson-relations .related-articles');
		let relatedProducts = $('.lesson-relations .related-products');

		if( width > 1024 ) {
			let relatedArticlesHeight = relatedArticles.height();
			let relatedProductsHeight =  relatedProducts.height();
			if( relatedArticlesHeight > relatedProductsHeight ) {
				relatedArticlesHeight += 32; // Add 2rem bottom padding
				relatedProducts.css('height', relatedArticlesHeight + 'px'); 
			}
		}
		else {
			relatedProducts.css('height', '');
		}
	}
}